import Dolom from "dolom";
import { WindowPlinko } from "plinko";

const elementId = ({ elementName }) => `kelvin-pulse-${elementName}`;
const dom = Dolom.init(document);

const injectContainer = function () {
  let container = document.getElementById("kelvin-pulse-container");
  if (container) {
    return container;
  }

  if (this.environment.type !== "extension") {
    const assetUrl = this.settings.assetUrl || this.settings.popupUrl;
    const stylesheet = dom.createElement("link", {
      rel: "stylesheet",
      href: `${assetUrl}/embed.css`,
    });
    document.head.append(stylesheet);
  }

  container = dom.createElement("div", {
    id: elementId({ elementName: "container" }),
  });
  document.body.appendChild(container);

  return container;
};

const addCoinEventListener = function (coin) {
  if (this.coinEventListener) {
    return;
  }

  this.coinEventListener = () => {
    coin.classList.remove("kelvin-pulse-display");
    coin.classList.add("kelvin-pulse-hide");
    this.showPopup();
  };

  coin.addEventListener("click", this.coinEventListener);
};

const removeCoinEventListener = function () {
  if (!this.coinEventListener) {
    return;
  }

  const coin = document.getElementById("kelvin-pulse-coin");
  if (!coin) {
    return;
  }

  coin.removeEventListener("click", this.coinEventListener);

  this.coinEventListener = null;
};

const url = function (path, params) {
  const paramArray = Object.entries(params);
  if (!paramArray.length) {
    return path;
  }

  return (
    path +
    "?" +
    paramArray
      .map(([key, value]) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(value);
      })
      .join("&")
  );
};

const fillAdapter = (adapter) => {
  const fill = (adapter) => {
    return {
      with(key, callback) {
        adapter[key] = adapter[key] || callback || (async () => {});
        return this;
      },
    };
  };

  fill(adapter)
    .with("getOptions", async () => {})
    .with("hasPendingPulse", async () => true)
    .with("isSnoozing", async () => false)
    .with("snooze", async () => {})
    .with("completePulse", async () => {});

  return adapter;
};

const plinkoMethods = {
  hasFeature(feature) {
    return (
      {
        showPopupAfterPulseLoaded: this.showPopup ? true : false,
        hideWhenNoPendingPulse: this.completePulse ? true : false,
        provideExtensionInfo: this.getOptions ? true : false,
        fetchPulse: this.adapter.fetchPulse ? true : false,
        sendPulseResponses: this.adapter.sendPulseResponses ? true : false,
      }[feature] || false
    );
  },

  getOptions() {
    return this.adapter.getOptions();
  },

  async injectCoin() {
    if (await this.adapter.isSnoozing()) {
      return;
    }

    let coin = document.getElementById("kelvin-pulse-coin");
    if (coin) {
      return coin;
    }

    const container = injectContainer.call(this);
    const elements = dom.createElements({
      coin: { tag: "div", id: elementId },
      boogie: { tag: "div", id: elementId },
    });

    elements.coin.append(elements.boogie);

    addCoinEventListener.call(this, elements.coin);

    container.append(elements.coin);

    return elements.coin;
  },

  async injectPopup() {
    const coin = document.getElementById("kelvin-pulse-coin");
    if (coin) {
      coin.classList.remove("kelvin-pulse-display");
      coin.classList.add("kelvin-pulse-hide");
    }

    let popup = document.getElementById("kelvin-pulse-popup");
    if (popup) {
      return { popup, ready: true };
    }

    const options = await this.getOptions();
    const environment = options.environment;
    const user = options.user;

    const iframeUrl = url(this.settings.popupUrl, {
      version: environment.version,
      env: environment.identifier,
      email: user.email,
      gid: user.gid,
    });
    const container = injectContainer.call(this);
    const elements = dom.createElements({
      popup: { tag: "div", id: elementId },
      iframe: { tag: "iframe", src: iframeUrl, id: elementId },
    });

    elements.popup.append(elements.iframe);
    ({ popup } = elements);

    container.append(popup);

    return { popup, ready: false };
  },

  snooze() {
    this.adapter.snooze();
  },

  async showCoin() {
    const coin = await this.injectCoin();
    if (!coin) {
      return;
    }

    const classes = coin.classList;
    classes.remove("kelvin-pulse-hide");
    classes.add("kelvin-pulse-display");
  },

  async showPopup() {
    const { popup, ready } = await this.injectPopup();

    if (!ready) {
      return;
    }

    const coin = document.getElementById("kelvin-pulse-coin");

    const classes = popup.classList;
    classes.remove("kelvin-pulse-hide");
    classes.add("kelvin-pulse-display");
  },

  hide() {
    const coin = document.getElementById("kelvin-pulse-coin");
    if (coin) {
      coin.classList.remove("kelvin-pulse-display");
      coin.classList.add("kelvin-pulse-hide");
    }

    const popup = document.getElementById("kelvin-pulse-popup");
    if (popup) {
      popup.classList.remove("kelvin-pulse-display");
      popup.classList.add("kelvin-pulse-hide");
    }
  },

  completePulse() {
    this.adapter.completePulse();
  },

  fetchPulse() {
    return this.adapter.fetchPulse();
  },

  sendPulseResponses(request) {
    return this.adapter.sendPulseResponses(request);
  },

  isCoinInjected() {
    return document.getElementById("kelvin-pulse-coin") ? true : false;
  },

  reinitializeEventHandlers() {
    const coin = document.getElementById("kelvin-pulse-coin");
    if (coin) {
      addCoinEventListener.call(this, coin);
    }
  },
};

const Embed = {
  async init(adapter) {
    const content = Object.create(Embed);

    content.adapter = fillAdapter(adapter);
    Object.assign(content, plinkoMethods);
    Object.assign(content, await adapter.getOptions());

    const popupUrl = new URL(content.settings.popupUrl);
    const popupOrigin = popupUrl.origin;
    content.plinko = WindowPlinko.init(plinkoMethods, popupOrigin, {
      thisArg: content,
    });

    return content;
  },

  async run() {
    if (this.isCoinInjected()) {
      this.reinitializeEventHandlers();
      return;
    }

    if (await this.adapter.hasPendingPulse()) {
      this.showCoin();
    }
  },

  dispose() {
    this.plinko.dispose();
    removeCoinEventListener.call(this);
  },
};

export default Embed;
export { Embed };
